import React from 'react';
import PropTypes from 'prop-types';

import { CookieLayer, Navigation, NavigationProvider, MainContainer, Footer } from '@ccg/ligalux';

import { getNextLinkProps } from '../../../helper';
import { withTranslation } from '../../../i18n';
import Seo from '../../shared/Seo/Seo';

const PageTemplate = ({
  cookieLayer,
  navigation,
  footer,
  social,
  seo,
  seoDefaults,
  children,
  t
}) => (
  <NavigationProvider>
    <Seo data={seo} defaults={seoDefaults} />

    <div className="transition__content">
      {navigation && navigation.mainLinks.length > 0 && (
        <Navigation
          menuLabel={t('navigation.menu')}
          backLabel={t('navigation.back')}
          homeLink={{ href: '/', asPath: '/' }}
          mainLinks={navigation.mainLinks.map(({ linkTo, subLinks, id }) => ({
            id,
            ...getNextLinkProps(linkTo),
            subLinks: subLinks.map(link => getNextLinkProps(link.linkTo))
          }))}
          secondaryLinks={[
            {
              href: { pathname: '/standard', query: { uri: 'imprint' } },
              asPath: '/imprint',
              label: t('navigation.imprint')
            },
            {
              href: { pathname: '/standard', query: { uri: 'kontakt' } },
              asPath: '/kontakt',
              label: t('navigation.contact')
            },
            {
              href: { pathname: '/standard', query: { uri: 'jobs' } },
              asPath: '/jobs',
              label: t('navigation.jobs')
            }
          ]}
        />
      )}
      <MainContainer>
        {children}
        <Footer
          homeLink={{ href: '/', asPath: '/' }}
          mainLinks={
            footer &&
            footer.mainLinks &&
            footer.mainLinks.map(link => getNextLinkProps(link.linkTo))
          }
          links={
            social &&
            social.socialLinks &&
            social.socialLinks.map(({ linkTo, socialService }) => ({
              socialService,
              ...getNextLinkProps(linkTo)
            }))
          }
          addressHeadline={t('footer.where')}
          addressLabel={t('footer.address')}
          socialHeadline={t('footer.follow')}
          navigationHeadline={t('footer.search')}
        />
      </MainContainer>
      {cookieLayer && <CookieLayer {...cookieLayer} />}
    </div>
  </NavigationProvider>
);

PageTemplate.propTypes = {
  navigation: PropTypes.shape().isRequired,
  footer: PropTypes.shape().isRequired,
  social: PropTypes.shape(),
  seo: PropTypes.shape().isRequired,
  seoDefaults: PropTypes.shape().isRequired,
  cookieLayer: PropTypes.shape().isRequired,
  children: PropTypes.node,
  t: PropTypes.func.isRequired
};

PageTemplate.defaultProps = { children: null, social: null };

export default withTranslation('common')(PageTemplate);
