import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { mq, space, getProperty, useReveal } from '@ccg/core';

import {
  Stage,
  Container,
  TeaserContainer,
  Typography,
  SpecialHeadline,
  Icon,
  Link,
  RevealBox
} from '@ccg/ligalux';

import PageTemplate from '../templates/page';

import relatedCasesQuery from '../../../graphql/queries/relatedCases.graphql';
import errorQuery from '../../../graphql/queries/error.graphql';

import { withTranslation } from '../../../i18n';

const RelatedCaseTeaser = styled.div`
  ${getProperty('grid-column', { default: '1 / span 6', m: '1 / span 12' })};

  > div {
    overflow: visible;
  }

  .relatedCaseTeaser__subline {
    margin-bottom: ${space(4)};
  }

  .relatedCaseTeaser__icon {
    transition: padding 200ms ease-in-out;

    ${mq('m', 'max')} {
      display: none;
    }
  }

  h2 {
    display: inline;
  }

  a:hover {
    .relatedCaseTeaser__icon {
      padding-left: ${space(2)};
    }
  }
`;

const Error = ({ t, statusCode, site }) => {
  const [ref, isVisible] = useReveal();
  const { data } = useQuery(errorQuery, {
    variables: {
      site
    }
  });

  const { data: relatedData } = useQuery(relatedCasesQuery, {
    variables: {
      site,
      limit: 1
    }
  });

  return data ? (
    <PageTemplate
      navigation={data.navigation}
      seo={data.entry}
      footer={data.footer}
      social={data.social}
      seoDefaults={data.seoDefaults}
      cookieLayer={data.cookieLayer}
    >
      <Stage
        variant={data.entry.contentType === 'advertising' ? 'default' : 'standard'}
        image={
          data.entry.image &&
          data.entry.image.length > 0 && {
            ...data.entry.image[0],
            srcset: {
              '1440w': data.entry.image[0].width1440,
              '960w': data.entry.image[0].width960,
              '720w': data.entry.image[0].width720,
              '375w': data.entry.image[0].width375
            }
          }
        }
        video={data.entry.video && data.entry.video.length > 0 && data.entry.video[0]}
        loop={data.entry.loop}
        headline={`${statusCode || 500} ${data.entry.headline || 'Sorry, something went wrong'}`}
        subline={data.entry.subline}
        title={data.entry.title}
      />

      {relatedData && relatedData.relatedEntries && relatedData.relatedEntries.length > 0 && (
        <Container headline={t('teaser.relatedContent')}>
          <TeaserContainer>
            {relatedData.relatedEntries.map(item => (
              <RelatedCaseTeaser key={item.id} ref={ref}>
                <RevealBox isVisible={isVisible}>
                  <Link
                    asPath={`/${item.uri}`}
                    href={{ pathname: '/case', query: { slug: item.slug } }}
                    title={item.title}
                    invertedHover
                    variant="hero"
                  >
                    <Typography variant="subline" className="relatedCaseTeaser__subline">
                      {item.client && item.client.length > 0 && item.client[0].title}
                    </Typography>
                    <SpecialHeadline as="h2" variant="inherit" text={item.title} />
                    <Icon icon="rightArrow" alignment="right" className="relatedCaseTeaser__icon" />
                  </Link>
                </RevealBox>
              </RelatedCaseTeaser>
            ))}
          </TeaserContainer>
        </Container>
      )}
    </PageTemplate>
  ) : null;
};

Error.propTypes = {
  t: PropTypes.func.isRequired,
  statusCode: PropTypes.string,
  site: PropTypes.string.isRequired
};

Error.defaultProps = { statusCode: 500 };

export default withTranslation('common')(Error);
